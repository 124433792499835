document.getElementById('newsletter-form').addEventListener('submit', function(event) {
  event.preventDefault();
  const email = document.getElementById('email').value;
  fetch('/api/v1/newsletter', {
    method: 'POST',
    headers:
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
    body: JSON.stringify({
      email: email,
    }),
  })
  .then(response => {
    console.log(response);
    if (response.status !== 200) {
      console.error('Error:', response.error);
      document.getElementById("newsletter-form").style.display = "none";
      document.getElementById("fail-message").style.display = "block";
    } else {
      console.log('Success:', response);
      document.getElementById("newsletter-form").style.display = "none";
      document.getElementById("thank-you-message").style.display = "block";
    }
  })
  .catch((error) => {
    console.error('Error:', error);
  });
});
