const navMain = document.getElementById('nav_bar_menu');
const navToggle = document.getElementById('nav_bar_toggle');
navToggle.addEventListener('click', function () {


navMain.classList.toggle('active');});

const searchButton = document.querySelector('.nav_bar_search');
const searchInput = document.querySelector('.nav_bar_search_input');
const searchForm = document.querySelector('#searchForm');

searchButton.addEventListener('click', function() {
if ( searchInput.classList.contains('hidden')) {
searchInput.classList.toggle('hidden');
}
else if (searchInput.value === ""){
searchInput.classList.toggle('hidden');
}
else {
searchForm.submit();
}
searchInput.value = ""
});